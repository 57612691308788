import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClient, ApolloClientOptions, ApolloLink, DefaultOptions, gql, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from "apollo-link-error";
import { environment } from 'src/environments/environment';
import { ErrorBroadcasterService } from './core/services/common/error-broadcaster.service';

export function createApollo(httpLink: HttpLink, errorService: ErrorBroadcasterService): ApolloClientOptions<any> {

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  }

  // URL of the GraphQL server here
  let uri = environment.hasura_http_endpoint;

  //Get tokenn from localStorage and set

  console.log("Creating Applo Client Object");

  let token = localStorage.getItem('access-token');
  let role = localStorage.getItem('ib-user-role');

  // Connection for qurey
  let http = httpLink.create({ uri: environment.hasura_http_endpoint });

  let middleware = new ApolloLink((operation, forward) => {

    token = localStorage.getItem('access-token');
    role = localStorage.getItem('ib-user-role');

    if (operation.operationName === 'getOrganization') {
      console.log('getting organization')
      operation.setContext({
        headers: {
          'assumedRole': 'anonymous',
        }
      })
    } else if (operation.operationName === 'Register') {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token')}`,
          register: 'true',
          assumedRole: role,
        },
      });
    } else if (operation.operationName === 'Login') {
      operation.setContext({
        headers: {
          assumedRole: role,
        },
      });
    }
    else {
      operation.setContext({
        headers: {
          Authorization: localStorage.getItem('access-token'),
          assumedRole: role
        },
      });
    }

    return forward(operation);
  });

  let httplink = middleware.concat(http);

  let headers: any;
  if (localStorage.getItem('current-module') === 'meeting-session') {
    headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('access-token'),
      assumedRole: role,
    }
  } else {
    // headers = {
    //   'Content-Type': 'application/json',
    //   assumedRole: 'anonymous'
    // }
  }

  //Connection socket for subscriptions
  let ws = new WebSocketLink({
    uri: environment.harusa_websocket_endpoint,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        return {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('access-token'),
            assumedRole: role,
          }
        };
      },
    },
  });

  //Depending on connection type http or socket connection
  let link = split(
    ({ query }) => {
      let data = getMainDefinition(query);
      // console.log(data, 'data in getMainDefinition')
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    httplink
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }: any) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${extensions?.code}`
        );
        if (extensions?.code == 401) {
          let REFRESH_TOKEN = gql`
            mutation RefreshToken($refresh_token: String = "") {
              refreshToken(refresh_token: $refresh_token) {
                access_token
                refresh_token
              }
            }
          `;

        }
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`, networkError);
    }
    errorService.httpError.next(networkError)
  });

  return {
    link: ApolloLink.from([errorLink as any, link]),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ErrorBroadcasterService],
    },
  ],
})
export class GraphQLModule {

  test() {
    alert('test triggered')
  }
}