import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent, ActivatedRoute } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { TokenStorageService } from './core/services/common/token-storage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth/auth.service';
import { ErrorBroadcasterService } from './core/services/common/error-broadcaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  getOrganizationLoad = false;
  getCurrentModuleLoad = false;
  httpError = false

  // Network checks
  onlineEvent: Observable<Event> | any;
  offlineEvent: Observable<Event> | any;
  subscriptions: Subscription[] = [];
  online = true;

  // Device width checks
  mobile: boolean;

  constructor(public storageService: TokenStorageService, public authService: AuthService, public router: Router, public activatedRoute: ActivatedRoute, private errorService: ErrorBroadcasterService) {
    console.log(localStorage.getItem('ib-user-role'),'role')
  }

  ngOnInit() {
    // Network check subcription
    this.checkNetwork();

    // Get current module
    this.getCurrentModuleLoad = true;
    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            if (event.url.startsWith('/meeting-session')) {
              this.storageService.saveCurrentModule('meeting-session');
            } else if (event.url.startsWith('/b2b')) {
              this.storageService.saveCurrentModule('b2b');
            } else {
              this.storageService.saveCurrentModule('ib');
            }
            this.getCurrentModuleLoad = false;
          }
        });

    // Get Organization details
    this.getOrganizationLoad = true;
    console.log(window.location);
    let org_slug = ''
    if (!environment.production) {
      org_slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
    } else {
      org_slug = window.location.origin.split('https://')[1].split('.')[0];
    }
    console.log(org_slug, 'org slug')
    this.authService.getOrganization(org_slug).subscribe((res: any) => {
      console.log(res);
      this.storageService.saveOrganization(JSON.stringify(res.data?.organizations?.[0]));
      this.getOrganizationLoad = false;
    }, (err: any) => {
      console.log(err);
      this.getOrganizationLoad = false;
    });

    // Users should log out after inactivity or directly closing the application and not being back in 2hrs.
    this.logoutAfterInactivity();

    this.errorService.httpError.asObservable().subscribe(error => {
      // alert(error)
      console.log(error);
      this.httpError = error
    });

  }

  checkNetwork(): any {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe((e: any) => {
      this.online = true;
      console.log('Back ONLINE!');
    }));
    this.subscriptions.push(this.offlineEvent.subscribe((e: any) => {
      this.online = false;
      console.log('OH NO! Offline!');
    }));
  }

  logoutAfterInactivity() {

    let timeout: any;
    const logout = () => {
      let currentModule = this.storageService.getCurrentModule();
      let role = this.storageService.getUserRole();
      let logoutRoute = '/';
      if (currentModule == 'b2b') {
        if (role == 'b2b-admin') {
          logoutRoute = '/b2b/admin/login';
        } else if (role == 'b2b-interviewer') {
          logoutRoute = '/b2b/interviewer/login';
        } else if (role == 'b2b-candidate') {
          logoutRoute = '/b2b/candidate/login';
        } else {
          logoutRoute = '/b2b/candidate/login';
        }
      } else if (currentModule == 'ib') {
        if (role == 'ib-console-admin') {
          logoutRoute = '/ib/console/login';
        } else if (role.includes('ib-ops')) {
          logoutRoute = '/ib/console/login';
        } else if (role == 'ib-interviewer') {
          logoutRoute = '/b2c/interviewer/login';
        } else if (role == 'b2c-candidate') {
          logoutRoute = '/b2c/candidate/login';
        } else {
          logoutRoute = '/b2c/candidate/login';
        }
      }
      if (this.storageService.getUserId()) {
        let lastActive = this.storageService.getLastActive();
        let now = Date.now();
        console.log('lastActive', lastActive);
        console.log('now', now);
        console.log('now - lastActive', now - lastActive);
        this.storageService.signOut();
        this.router.navigate([logoutRoute]);
      }
    };

    // 48 HOURS IN MILLISECONDS
    const FORTY_EIGHT_HOURS = 48 * 60 * 60 * 1000;

    const resetTimeout = () => {
      let lastActive = this.storageService.getLastActive();
      let now = Date.now();
      if (lastActive && now - lastActive > FORTY_EIGHT_HOURS) {
        logout();
      }
      clearTimeout(timeout);
      timeout = setTimeout(logout, FORTY_EIGHT_HOURS);
      if (this.storageService.getUserId()) {
        this.storageService.saveLastActive(now);
      }
    };

    window.addEventListener('load', resetTimeout);
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('mousedown', resetTimeout);
    window.addEventListener('click', resetTimeout);
    window.addEventListener('scroll', resetTimeout);
    window.addEventListener('keypress', resetTimeout);
  }
}
