<button class="button d-flex align-items-center justify-content-center {{class}} {{btnColors[btnType]}} {{btnSizes[btnSize]}} font-weight-400" [disabled]="isDisabled || isLoading">
    @if (hasIconFromIconComponent && iconAlignment === 'left') {
        <span class="{{btnSizes[btnSize]}}" [ngClass]="hasBtnText ? 'ib-mr-4' : ''">
            <app-icons svgName="{{iconPath}}"></app-icons>
        </span>
    }
    @if (hasBtnText) {
        <span>{{btnText}}</span>
    }
    @if (hasIconFromIconComponent && iconAlignment === 'right') {
        <span class="{{btnSizes[btnSize]}}" [ngClass]="hasBtnText ? 'ib-ml-4' : ''">
            <app-icons svgName="{{iconPath}}"></app-icons>
        </span>
    }
    @if (hasIcon) {
        <span class="{{btnSizes[btnSize]}}" [ngClass]="hasBtnText ? 'ms-2' : ''">
            <img src="{{iconPath}}">
        </span>
    }
    @if (isLoading) {
        <span class="d-flex" [ngClass]="hasBtnText ? 'ms-2' : ''">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </span>
    }
</button>
