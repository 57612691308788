import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorBroadcasterService {
  public httpError: BehaviorSubject<any>;
  constructor() {
    this.httpError = new BehaviorSubject<any>(false);
  }
}