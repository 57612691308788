// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // For local use & development

  name: 'Dev',
  production: true,
  logs: true,

  auth_endpoint: 'https://authentication.studie.in',
  http_endpoint: 'https://hasura.studie.in',
  hasura_http_endpoint: 'https://hasura.studie.in/v1/graphql',
  harusa_websocket_endpoint: 'wss://hasura.studie.in/v1/graphql',
  storage_endpoint: 'https://storage.studie.in',
  meet_endpoint: 'https://meets.studie.in',
  meet_ws_endpoint: `wss://meets.studie.in/ws`,
  s3_bucket_name: 'ib-storage-dev',
  s3_recordings_bucket: 'ib-twilio-recordings-dev',
  cameratag_app_id: 'a-0722ea00-605e-013b-df82-0abe8b919efd',
  hours_to_expire: 3,
  supabase_config: {
    reference_url: 'https://supabase.interviewbuddy.xyz',
    public_key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgInJvbGUiOiAiYW5vbiIsCiAgICAiaXNzIjogInN1cGFiYXNlIiwKICAgICJpYXQiOiAxNjg4NDA5MDAwLAogICAgImV4cCI6IDE4NDYyNjE4MDAKfQ.cTJCJEWV8zez3gr-pH7v8OGKSFATnBlCVVsnFsoXYoY'
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.