import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/core/services/common/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Provider } from '@supabase/supabase-js';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Message } from 'primeng/api';
import { B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, CONSOLE_OPS, INTERVIEWER } from 'src/app/general/constants/app-constants';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  userType: string = this.route.snapshot.data['user_role']
  step = 0
  message: Message[];
  incorrectOTPAttempts = 0;
  errorMessage = '';
  timer = 0;
  org_slug: string = "";
  loginLink = '';


  //Form Controls
  name: FormControl = new FormControl('');
  otp = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  email_id = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}$')]);
  confirmPassword = new FormControl('', [Validators.required]);

  //States
  registerLoad = false;
  otpValidationLoad: boolean = false;
  sendOtpLoad: boolean = false;
  otpIsSent: boolean = false;
  otpInvalid: boolean = false;
  verifyOtpLoad: boolean = false;
  isExistingEmail: boolean = false;
  showPassword: boolean = false;
  showCPassword: boolean = false;
  incorrectOTPAttemptsPopup: boolean = false;
  errorPopup: boolean = false;
  isExistingUserInSupabase: boolean = false;
  isValidPassword: boolean = false;
  emailInvalid: boolean = false;
  
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public storageService: TokenStorageService,
    public authService: AuthService,
    public http: HttpClient
  ) {
    if (this.userType == INTERVIEWER) {
      this.loginLink = window.location.origin + '/b2c/interviewer/login'
    };
    if (this.userType == CONSOLE_ADMIN) {
      this.loginLink = window.location.origin + '/ib/console/login'
    };
    if (this.userType.includes(CONSOLE_OPS)) {
      this.loginLink = window.location.origin + '/ib/console/login'
    };
    if (this.userType == B2B_ADMIN) {
      this.loginLink = window.location.origin + '/b2b/admin/login'
    };
    if (this.userType == B2B_INTERVIEWER) {
      this.loginLink = window.location.origin + '/b2b/interviewer/login'
    };
    if (this.userType == B2B_CANDIDATE) {
      this.loginLink = window.location.origin + '/b2b/candidate/login'
    };
    if (this.userType == B2C_CANDIDATE) {
      this.loginLink = window.location.origin + '/b2c/candidate/login'
    };
  }

  ngOnInit(): void {
    //Clear all local storage variable before signup
    this.storageService.clearLocalStorage();

    //Saving user type in local storageService
    this.storageService.saveUserRole(this.userType);

    // Validate Google Auth if redirected after Google Authentication
    if (this.storageService.getGoogleAuthRedirect() === 'true') {
      this.registerLoad = true;
      this.validateOAuthLogin();
    }

    if (!environment.production) {
      this.org_slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
    } else {
      this.org_slug = window.location.origin.split('https://')[1].split('.')[0];
    }
  }

  sendOtp() {
    this.sendOtpLoad = true;
    this.http.post(environment.auth_endpoint + '/auth/send-otp', {
      email: this.email_id.value
    }).subscribe({
      next: (res) => console.log(res),
      error: (err) => {
        console.log("Error is raised while trying to send OTP: ", err)
        if (err.error.message) {
          this.emailInvalid = true;
        } else {
          this.errorPopup = true;
          this.errorMessage = "An Unknown Error Occurred! Please try again."
        }
        this.otpIsSent = false;
        this.email_id.reset();
        this.sendOtpLoad = false;
      },
      complete: () => {
        this.emailInvalid = false;
        this.otpIsSent = true;
        this.otp.reset();
        this.timer = 60
        let time = setInterval(() => {
          this.timer -= 1
          if (this.timer <= 0) {
            clearInterval(time)
          }
        }, 1000)
        this.sendOtpLoad = false;
      }
    })
  }

  verifyOtp() {
    this.verifyOtpLoad = true;

    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

    this.http.post(environment.auth_endpoint + '/auth/verify-otp', {
      email: this.email_id.value,
      otp: this.otp.value,
      role: this.storageService.getUserRole(),
      organization_slug: this.org_slug,
      functionality: 'register'
    }).subscribe({
      next: (res: any) => {
        this.step = 1;
        if (res.message === 'User aldready exists in Supabase') {
          this.isExistingUserInSupabase = true;
        } else {
          this.isExistingUserInSupabase = false;
        }
        this.verifyOtpLoad = false;
      },
      error: (err) => {
        console.log("Error is raised while trying to verifyOTP with following message: ", err)
        if (err.error.message === 'User already exists for this role') {
          this.isExistingEmail = true;
          this.step = 3;
        } else {
          this.isExistingEmail = false;
          this.otpInvalid = true;
          this.incorrectOTPAttempts = this.incorrectOTPAttempts + 1;
          if (this.incorrectOTPAttempts >= 3) {
            this.errorPopup = true;
            this.errorMessage = "You've entered Incorrect OTP 3 Times!. Please try again.";
          }
        }
        this.verifyOtpLoad = false;
      }
    })
  }

  async registerWithEmailAndPassword() {
    this.registerLoad = true;
    this.storageService.saveRegisterState('true');

    const email: string = this.email_id.value ? this.email_id.value : '';
    const password: string = this.password.value ? this.password.value : '';

    try {
      if (email === '' || password === '') {
        console.error('Invalid Email or Password')
        this.errorPopup = true;
        this.errorMessage = "Please provide a valid Email and Password!"
        return
      }
      this.storageService.saveRegisterState('true');

      //Getting Organization Slug
      this.org_slug = this.getOrganizationSlug();
      console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

      this.http.post(environment.auth_endpoint + '/auth/register-password', {
        email: email,
        password: password,
        role: this.storageService.getUserRole(),
        organization_slug: this.org_slug
      }).subscribe({
        next: (res: any) => {
          if(res.message === 'User already exists need to add role') {
            this.isExistingEmail = true;
          }
          this.step = 2;
          console.log("Registration was Successful with Following Response: ", res)
          setTimeout( () => {
            console.log(this.loginLink)
            console.log(this.userType)
            if (this.userType == INTERVIEWER) {
              this.router.navigate(['/b2c/interviewer/login'])
            };
            if (this.userType == CONSOLE_ADMIN) {
              this.router.navigate(['/ib/console/login'])
            };
            if (this.userType.includes(CONSOLE_OPS)) {
              this.router.navigate(['/ib/console/login'])
            };
            if (this.userType == B2B_ADMIN) {
              this.router.navigate(['/b2b/admin/login'])
            };
            if (this.userType == B2B_INTERVIEWER) {
              this.router.navigate(['/b2b/interviewer/login'])
            };
            if (this.userType == B2B_CANDIDATE) {
              this.router.navigate(['/b2b/candidate/login'])
            };
            if (this.userType == B2C_CANDIDATE) {
              this.router.navigate(['/b2c/candidate/login'])
            };
          }, 1000)
          this.registerLoad = false;

        },
        error: (err) => {
          console.log("Error is raised while trying to Registering the User with following message: ", err)
          if (err.error.message === 'User already exists for this role') {
            this.step = 3;
            this.registerLoad = false;
          } else {
            console.log("Else Exec")
            throw new Error(err);
          }
        }
      })
    } catch (err) {
      console.log('Error rasied while signing up the user at line 125:\n', err)
      this.errorMessage = "An unknow error occured at IB End!. Please try again.";
      this.errorPopup = true;
      this.registerLoad = false;
    }
    this.isExistingUserInSupabase = false;
  }


  // Register with Google Provider
  async registerWithOAuth(type: Provider) {
    this.storageService.saveGoogleAuthRedirect('true');
    this.storageService.saveRegisterState('true');
    try {
      const { data, error } = await this.authService.supabaseAuth.auth.signInWithOAuth({
        provider: type,
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent'
          },
          redirectTo: window.location.origin
        }
      });
      console.log("Response from signInWithOAuth(): ", data, error)
    } catch (err) {
      console.log("An Error is Raised while Signing In User", err)
    }
  }

  async validateOAuthLogin() {
    this.registerLoad = true;
    const { data, error }: any = await this.authService.supabaseAuth.auth.getSession();
    console.log('User Session after Sign Up is Done: ', data, error)
    if (data && data.session) {
      this.storageService.saveAccessToken(data?.session.access_token);
      this.storageService.saveRefreshToken(data?.session.refresh_token);

      //Getting Organization Slug
      this.org_slug = this.getOrganizationSlug();
      console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

      try {
        this.http.post(environment.auth_endpoint + '/auth/register-oauth', {
          jwt: data?.session.access_token,
          role: this.storageService.getUserRole(),
          organization_slug: this.org_slug
        }).subscribe({
          next: (res: any) => {
            console.log("Registration was Successful with Following Response: ", res)
            this.step = 2;
            this.router.navigate([this.loginLink])
            this.registerLoad = false;
          },
          error: (err) => {
            console.log("Error is raised while trying to Registering the User with following message: ", err)
            if (err.error.message === 'User already exists for this role') {
              this.step = 3;
              this.registerLoad = false;
            } else {
              throw new Error(err);
            }
          }
        })
      } catch(err) {
        console.log('Error rasied while signing up the user with OAuth:\n', err)
        this.errorMessage = "An unknow error occured at IB End!. Please try again.";
        this.errorPopup = true;
        this.registerLoad = false;
      }
    } else if(error) {
      console.log('Error while trying to register with OAuth:\n', error.message)
      this.errorPopup = true;
      this.errorMessage = "An unexpected error occured!. Please try again."
      this.registerLoad = false;
    } else {
      console.log("Improper Response received from Supabase OAuth: ", data)
      this.errorPopup = true;
      this.errorMessage = "An unexpected error occured!. Please try again."
      this.registerLoad = false;
    }
    this.storageService.clearGoogleAuthRedirect();
  }

  clearRegisterForm() {
    this.email_id.reset();
    this.name.reset();
    this.otp.reset();
    this.password.reset();
    this.confirmPassword.reset();
    this.step = 0;
    this.errorPopup = false;
    this.errorMessage = "";
    this.registerLoad = false;
    this.otpValidationLoad = false;
    this.sendOtpLoad = false;
    this.otpIsSent = false;
    this.otpInvalid = false;
    this.verifyOtpLoad = false;
    this.isExistingEmail = false;
    this.showPassword = false
    this.showCPassword = false
    this.incorrectOTPAttempts = 0;
  }

  getOrganizationSlug() {
    let slug;
    console.log("Organization isn't got properly in ngOninit, fetching Slug from Domain")
    if (!environment.production) {
      slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
      console.log("Set Org Slug: ", slug)
    } else {
      slug = window.location.origin.split('https://')[1].split('.')[0];
    }
    return slug;
  }
}
