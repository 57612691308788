import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { AuthData } from '../../interfaces/auth/auth';
import { createClient } from '@supabase/supabase-js';

import { environment } from 'src/environments/environment';


@Injectable()
export class AuthService extends AuthData {

  public supabaseAuth;
  public loginPreformedObserver: Subject<any> = new Subject<any>();
  constructor(public apollo: Apollo) {
    super();
    this.supabaseAuth = createClient( environment.supabase_config.reference_url, environment.supabase_config.public_key );
  }

  registerIB(): Observable<any> {
    return this.apollo.mutate({
      mutation: this.REGISTER,
    });
  }

  loginIB(): Observable<any> {
    return this.apollo.watchQuery({
      query: this.LOGIN,
      variables: {
        organization_id: JSON.parse(localStorage.getItem('organization') || '{}').organization_id
      },
      errorPolicy: 'all'
    }).valueChanges;
  }

  getOrganization(org_slug:string): Observable<any> {
    return this.apollo.watchQuery({
      query: this.GET_ORGANIZATION,
      variables: {
        organization_slug: org_slug
      }
    }).valueChanges;
  }
}
