import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import PrimengModule from '../primeng/primeng.module';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BadgeComponent } from './badge/badge.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { ToggleComponent } from './toggle/toggle.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MessageAlertComponent } from './message-alert/message-alert.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { IconsComponent } from './icons/icons.component';
import { StepperComponent } from './stepper/stepper.component';

@NgModule({
  declarations: [
    ButtonComponent,
    InputComponent,
    BadgeComponent,
    CheckboxComponent,
    RadioButtonComponent,
    ToggleComponent,
    SidebarComponent,
    MessageAlertComponent,
    DropdownComponent,
    IconsComponent,
    StepperComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    ReactiveFormsModule
  ],
  exports: [
    ButtonComponent,
    InputComponent,
    BadgeComponent,
    CheckboxComponent,
    RadioButtonComponent,
    ToggleComponent,
    SidebarComponent,
    MessageAlertComponent,
    DropdownComponent,
    IconsComponent,
    StepperComponent
  ]
})
export class BaseComponentsModule { }
