import { Component, Input } from '@angular/core';

type BadgeColor = 'success_solid' | 'success_filled' | 'error_solid' | 'error_filled' | 'warning_solid' | 'warning_filled'
                   | 'gray_solid' | 'gray_filled' | 'brand_solid' | 'brand_filled' | 'blue_solid' | 'blue_filled' | 'pink_solid' | 'pink_filled' | 'teal_solid' | 'teal_filled'

@Component({
  selector: 'app-new-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {

  @Input({ required: false }) hasIcon: boolean = false;
  @Input({ required: false }) iconPath: string = '';
  @Input({required:false}) iconName:string='';
  @Input({ required: false }) isDismissable: boolean = false;
  @Input({ required: true }) text: any = '';
  @Input({ required: true }) badgeType: 'regular' | 'curved' = 'regular';
  @Input({ required: true }) badgeColor: BadgeColor = 'brand_solid';
  @Input({ required: false }) styleClass: string = '';

  badgeColors: any = {
    success_filled: 'bg-success-50 text-success-500',
    success_solid: 'bg-success-500 text-white',
    error_filled: 'bg-error-50 text-error-500',
    error_solid: 'bg-error-500 text-white',
    warning_filled: 'bg-warning-50 text-warning-500',
    warning_solid: 'bg-warning-500 text-white',
    gray_filled: 'bg-gray-50 text-gray-500',
    gray_solid: 'bg-gray-500 text-white',
    brand_filled: 'bg-brand-50 text-brand-500',
    brand_solid: 'bg-brand-500 text-white',
    blue_filled: 'bg-blue-50 text-blue-500',
    blue_solid: 'bg-blue-500 text-white',
    pink_filled: 'bg-pink-50 text-pink-500',
    pink_solid: 'bg-pink-500 text-white',
    teal_filled: 'bg-teal-50 text-teal-500',
    teal_solid: 'bg-teal-500 text-white',
  }

}
