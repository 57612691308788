import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { AdminData } from '../../interfaces/admin/admin';

@Injectable()
export class AdminService extends AdminData {

  constructor(public apollo: Apollo) {
    super();
  }

  getOrgAdminProfile(variables: any): Observable<any> {
    return this.apollo.watchQuery({
      query: this.ADMIN_ORG_PROFILE,
      variables: variables
    }).valueChanges;
  }

  updateAdminProfile(userId: String, profileData: any, log_object: any): Observable<any> {
    return this.apollo.watchQuery({
      query: this.UPDATE_ADMIN_PROFILE,
      variables: {
        userId,
        profileData,
        log_object
      }
    }).valueChanges;
  }


}