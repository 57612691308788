import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, INTERVIEWER, CONSOLE_OPS } from './general/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  constructor(public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const IB_ROLES = [CONSOLE_ADMIN, CONSOLE_OPS];
    const B2C_ROLES = [B2C_CANDIDATE, INTERVIEWER];
    const B2B_ROLES = [B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER];
    const assumedRole = route.data?.user_role;
    console.log(route.data);
    if (window.location.port === "4200" || window.location.hostname.startsWith('ib.')) {
      if (!IB_ROLES.includes(assumedRole) && assumedRole.includes('ib') && assumedRole !== 'ib-interviewer') {
        this.router.navigate(['/ib/console/login']);
        return false;
      }
      if (!B2C_ROLES.includes(assumedRole) && (assumedRole.startsWith('b2c') || assumedRole === 'ib-interviewer')) {
        this.router.navigate(['/b2c/candidate/login']);
        return false;
      }
      if (!IB_ROLES.includes(assumedRole) && !B2C_ROLES.includes(assumedRole)) {
        this.router.navigate(['/ib/console/login']);
        return false;
      }
    } else {
      if (!B2B_ROLES.includes(assumedRole)) {
        this.router.navigate(['/b2b/candidate/login']);
        return false;
      }
    }
    return true;
  }
}
