import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';

export abstract class AdminData {

  UPDATE_ADMIN_PROFILE = gql`
  mutation UpdateUserProfile($userId: uuid!, $profileData: user_profiles_set_input!, $log_object: logs_insert_input = {}) {
    update_user_profiles_by_pk(pk_columns: {user_organization_id: $userId}, _set: $profileData) {
      user_organization_id
      user_status
    }
    insert_logs_one(object: $log_object) {
      log_id
    }
  }  
  `;

  ADMIN_ORG_PROFILE = gql`
    query MyQuery($searchObject: user_profiles_bool_exp) {
      user_profiles(where:$searchObject) {
        user_organization_id
        alternate_phone_no
        email_id
        first_name
        gender
        last_name
        nick_name
        profile_pic_name
        profile_pic_key
        phone_no
        user_id
        timezone
        user_status
        year_of_birth
        phone_no_country_code
        alternate_phone_no_country_code
        user_status_reason
        notify_config    
        user_roles {
          role_name
        }   
      }   
    }
  `;

  abstract getOrgAdminProfile(variables: any): Observable<any>;
  abstract updateAdminProfile(userId: String, ProfileData: any, log_object: any): Observable<any>;
}
