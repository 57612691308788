import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent {

  @Input({ required: true }) inputSize: 'small' | 'regular' | 'large' = 'regular'; // Acceptable values: small, regular, large.
  @Input({ required: true }) hasInputLabel: boolean = false;
  @Input({ required: false }) inputLabel: string = '';
  @Input({ required: true }) hasPrefixIcon: boolean = false;
  @Input({ required: false }) prefixIconPath: string = '';
  @Input({ required: false }) inputPlaceholder: string = '';
  @Input({ required: true }) hasFormControl: boolean;
  @Input({ required: false }) control: FormControl;
  @Input({ required: false }) hasClearBtn: boolean = false;
  @Input({ required: true }) isDisabled: boolean = false;
  @Input({ required: true }) isReadOnly: boolean = false;
  @Input({ required: false }) requiredErrorMsg: string = '';
  @Input({ required: false }) patternErrorMsg: string = '';
  @Input({ required: false }) type: 'password' | 'text' = 'text'

  @Input({required: false}) value: any = '';

  @Output('onEvent') emitter: EventEmitter<any> = new EventEmitter<any>();

  handleEvent(eventType: string) {
    this.emitter.emit(this.hasFormControl ? eventType : this.value);
  }

}
