import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token'
const IB_USER_ROLE = 'ib-user-role';
const IB_USER_TIME_ZONE = 'ib-user-time-zone';
const IB_USER_ID = 'ib-user-id';
const GOOGLE_AUTH_REDIRECT = 'true';
const ORGANIZATION = 'organization';
const CURRENT_MODULE = 'current-module';
const ALERTS = 'alerts';
const USER_STATUS = 'user-status';
const LAST_ACTIVE = 'last-active';
const VERSION = 'version';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {

  interviewerNotAssigned: boolean = false;
  interviewerNotReviewed: boolean = false;
  reviewsNotSubmitted: boolean = false;
  paymentPendingApproval: boolean = false;
  todaysInterviews:boolean = false;
  tabIndex: any = null;
  meetingId: any = '';
  searchCalled: boolean = false;

  interview_group_instructions: any = null;

  bucketName: any = environment.s3_bucket_name;
  storageURL: any = environment.storage_endpoint

  constructor(public authService: AuthService) {}

  async signOut() {
    const { error } = await this.authService.supabaseAuth.auth.signOut();
    console.log(error, 'error in signout');
    localStorage.clear();
  }

  public saveLastActive(timestamp: number): void {
    localStorage.setItem(LAST_ACTIVE, timestamp.toString());
  }

  public getLastActive(): number {
    return Number(localStorage.getItem(LAST_ACTIVE));
  }

  public saveAccessToken(token: string): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  public saveRefreshToken(token: string): void {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  public saveUserRole(userRole: string): void {
    localStorage.removeItem(IB_USER_ROLE);
    localStorage.setItem(IB_USER_ROLE, userRole);
  }

  public saveUserStatus(userStatus: string): void {
    localStorage.removeItem(USER_STATUS);
    localStorage.setItem(USER_STATUS, userStatus);
  }

  public saveUserTimeZone(timezone: string): void {
    localStorage.removeItem(IB_USER_TIME_ZONE);
    localStorage.setItem(IB_USER_TIME_ZONE, timezone);
  }

  public saveGoogleAuthRedirect(status: string): void {
    localStorage.setItem(GOOGLE_AUTH_REDIRECT, status);
  }

  public saveRegisterState(register: string): void {
    localStorage.setItem('register', register);
  }

  public saveUserId(userId: string): void {
    localStorage.setItem(IB_USER_ID,userId);
  }

  public saveOrganization(organization: string): void {
    localStorage.setItem(ORGANIZATION, organization);
  }

  public saveAlertsData(alertsData: any): void {
    localStorage.setItem(ALERTS, alertsData);
  }

  public saveVersionNumber(version: any): void {
    localStorage.setItem(VERSION, version);
  }

  public getAlertsData(): any {
    localStorage.getItem(ALERTS) || '';
  }

  public saveCurrentModule(currentModule: string): void {
    localStorage.setItem(CURRENT_MODULE, currentModule);
  }

  public getGoogleAuthRedirect(): string {
    return localStorage.getItem(GOOGLE_AUTH_REDIRECT) || '';
  }

  public getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN) || '';
  }

  public getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN) || '';
  }

  public getUserRole(): string {
    return localStorage.getItem(IB_USER_ROLE) || '';
  }

  public getUserTimeZone(): string {
    return localStorage.getItem(IB_USER_TIME_ZONE) || 'Asia/Calcutta';
  }

  public getUserId(): string {
    return localStorage.getItem(IB_USER_ID) || '';
  }

  public getOrganization(): string {
    return localStorage.getItem(ORGANIZATION) || '';
  }

  public getCurrentModule(): string {
    return localStorage.getItem(CURRENT_MODULE) || '';
  }

  public getUserStatus(): string {
    return localStorage.getItem(USER_STATUS) || '';
  }

  public getRegisterState(): string {
    return localStorage.getItem('register') || '';
  }

  public getVersionNumber(): string {
    return localStorage.getItem(VERSION) || '-1';
  }

  public clearLocalStorage(): void {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('ib-user-role');
    localStorage.removeItem('ib-user-id');
    localStorage.removeItem('current-module');
    localStorage.removeItem('alerts data');
    localStorage.removeItem('register');
    localStorage.removeItem(IB_USER_TIME_ZONE)
  }

  public clearGoogleAuthRedirect(): void {
    localStorage.removeItem(GOOGLE_AUTH_REDIRECT);
  }

}
