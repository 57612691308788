import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrl: './message-alert.component.scss'
})
export class MessageAlertComponent {

  @Input({ required: true }) alertText: string = '';
  @Input({ required: true }) alertType: 'info' | 'success' | 'warning' | 'danger' = 'info';

  alertColors: any = {
    info: 'bg-info-50 text-info-500 border-info br-6px',
    success: 'bg-success-50 text-success-500 border-success br-6px',
    warning: 'bg-warning-50 text-warning-500 border-warning br-6px',
    danger: 'bg-danger-50 text-danger-500 border-danger br-6px'
  }

  constructor() { }

}
