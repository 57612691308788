<div class="badge d-inline-block {{badgeColors[badgeColor]}} {{styleClass}}" [ngClass]="badgeType === 'regular' ? 'br-6' : 'br-20'">
    <div class="row g-0 align-items-center font-12 font-weight-400 lh-20">
        @if (hasIcon) {
            <div class="col-auto d-flex align-items-center" [ngClass]="badgeType === 'regular' ? 'mr-4' : 'mr-6'">
              @if(iconName!='')
              {
                <app-icons [svgName]="iconName"></app-icons>

              }@else {
                <svg xmlns="http://www.w3.org/2000/svg" width=".6em" height=".6em" viewBox="0 0 12 12" fill="currentColor">
                    <path d="M6 10.8C3.34896 10.8 1.2 8.65104 1.2 6C1.2 3.34896 3.34896 1.2 6 1.2C8.65104 1.2 10.8 3.34896 10.8 6C10.8 8.65104 8.65104 10.8 6 10.8Z" fill="currentColor"/>
                </svg>
              }
            </div>
        }
        <div class="col-auto">
            {{text}}
        </div>
        @if (isDismissable) {
            <div class="col-auto ib-ml-4 d-flex align-items-center">
                <svg width="1em" height="1em" viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.55105 2.55106C2.59881 2.50317 2.65555 2.46518 2.71802 2.43926C2.78049 2.41334 2.84745 2.4 2.91509 2.4C2.98272 2.4 3.04969 2.41334 3.11215 2.43926C3.17462 2.46518 3.23136 2.50317 3.27912 2.55106L6.00013 5.27309L8.72114 2.55106C8.76894 2.50325 8.8257 2.46533 8.88816 2.43946C8.95062 2.41359 9.01756 2.40027 9.08517 2.40027C9.15278 2.40027 9.21972 2.41359 9.28219 2.43946C9.34465 2.46533 9.4014 2.50325 9.44921 2.55106C9.49701 2.59886 9.53493 2.65562 9.56081 2.71808C9.58668 2.78054 9.59999 2.84749 9.59999 2.91509C9.59999 2.9827 9.58668 3.04965 9.56081 3.11211C9.53493 3.17457 9.49701 3.23132 9.44921 3.27913L6.72717 6.00014L9.44921 8.72114C9.49701 8.76895 9.53493 8.8257 9.56081 8.88816C9.58668 8.95062 9.59999 9.01757 9.59999 9.08518C9.59999 9.15279 9.58668 9.21973 9.56081 9.28219C9.53493 9.34465 9.49701 9.40141 9.44921 9.44921C9.4014 9.49702 9.34465 9.53494 9.28219 9.56081C9.21972 9.58668 9.15278 9.6 9.08517 9.6C9.01756 9.6 8.95062 9.58668 8.88816 9.56081C8.8257 9.53494 8.76894 9.49702 8.72114 9.44921L6.00013 6.72718L3.27912 9.44921C3.23132 9.49702 3.17456 9.53494 3.1121 9.56081C3.04964 9.58668 2.98269 9.6 2.91509 9.6C2.84748 9.6 2.78053 9.58668 2.71807 9.56081C2.65561 9.53494 2.59886 9.49702 2.55105 9.44921C2.50325 9.40141 2.46532 9.34465 2.43945 9.28219C2.41358 9.21973 2.40026 9.15279 2.40026 9.08518C2.40026 9.01757 2.41358 8.95062 2.43945 8.88816C2.46532 8.8257 2.50325 8.76895 2.55105 8.72114L5.27309 6.00014L2.55105 3.27913C2.50317 3.23137 2.46518 3.17463 2.43926 3.11216C2.41334 3.04969 2.39999 2.98272 2.39999 2.91509C2.39999 2.84746 2.41334 2.78049 2.43926 2.71803C2.46518 2.65556 2.50317 2.59882 2.55105 2.55106Z" fill="currentColor"/>
                </svg>
            </div>
        }
    </div>
</div>
