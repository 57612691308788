import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss'
})
export class IconsComponent {
  @Input({required: true}) svgName: string;
  @Input({required: false}) shouldCenter: boolean = true;
}
