import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TokenStorageService } from 'src/app/core/services/common/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, CONSOLE_OPS, INTERVIEWER } from 'src/app/general/constants/app-constants';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  userType: string = this.route.snapshot.data['user_role']

  email_id = new FormControl('', [Validators.email]);
  otp = new FormControl('', [Validators.minLength(6), Validators.maxLength(6)])

  loading: boolean = false;
  otpIsSent: boolean = false;
  sendOtpLoad: boolean = false;
  otpInvalid: boolean = false;
  verifyOtpLoad: boolean = false;
  errorPopup: boolean = false;
  isExistingEmail: boolean = false;

  step = 0;
  timer = 0;
  incorrectOTPAttempts = 0;
  storageEndpoint = environment.storage_endpoint;
  errorMessage = '';
  organization: any;
  org_slug = '';
  emailInvalid: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public storageService: TokenStorageService,
    public authService: AuthService,
    public http: HttpClient
  ) { }

  ngOnInit() {
    //Clear all local storage variable before forgot password
    this.storageService.clearLocalStorage();

    //Saving user type in local storageService
    this.storageService.saveUserRole(this.userType);
    if (!this.storageService.getOrganization()) {
      let org_slug = ''
      if (!environment.production) {
        org_slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
      } else {
        org_slug = window.location.origin.split('https://')[1].split('.')[0];
      }
      console.log("!!!", this.org_slug)
      console.log("!!!", org_slug)
      this.authService.getOrganization(org_slug).subscribe({
        next: (data) => {
          console.log(data, "getOrganization Results")
          this.organization = data.data?.getOrganization;
          this.storageService.saveOrganization(JSON.stringify(data.data?.getOrganization))
        },
        error: (err) => {
          console.log("ERROR")
        }
      })
    } else {
      this.organization = JSON.parse(this.storageService.getOrganization());
    }
    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)
  }

  sendOtp() {
    this.sendOtpLoad = true;
    this.http.post(environment.auth_endpoint + '/auth/send-otp', {
      email: this.email_id.value
    }).subscribe({
      next: (res) => console.log(res),
      error: (err) => {
        console.log("Error is raised while trying to send OTP: ", err)
        if(err.error.message) {
          this.emailInvalid = true;
        } else {
          this.errorPopup = true;
          this.errorMessage = "An Unknown Error Occurred! Please try again."
        }
        this.email_id.reset();
        this.otpIsSent = false;
        this.sendOtpLoad = false;
      },
      complete: () => {
        this.emailInvalid = false;
        this.otpIsSent = true;
        this.otp.reset();
        this.timer = 60
        let time = setInterval(() => {
          this.timer -= 1
          if (this.timer <= 0) {
            clearInterval(time)
          }
        }, 1000);
        this.sendOtpLoad = false;
      }
    })
  }

  verifyOtp() {
    this.verifyOtpLoad = true;

    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

    this.http.post(environment.auth_endpoint + '/auth/verify-otp', {
      email: this.email_id.value,
      otp: this.otp.value,
      role: this.storageService.getUserRole(),
      organization_slug: this.org_slug,
      functionality: 'forgot-password'
    }).subscribe({
      next: (res: any) => {
        console.log("Response from VerifyOTP:\n", res)
        this.isExistingEmail = true;
        this.step = 1;
        this.verifyOtpLoad = false;
      },
      error: (err) => {
        console.log("Error is raised while trying to verify OTP with following message: ", err.error)
        if (err.error.message === 'There is no account linked with the provided email') {
          this.isExistingEmail = false;
          this.step = 1;
        } else {
          this.otpInvalid = true;
          this.incorrectOTPAttempts = this.incorrectOTPAttempts + 1;
          if (this.incorrectOTPAttempts >= 3) {
            this.errorMessage = "You've entered Incorrect OTP 3 Times!. Please try again.";
            this.errorPopup = true;
          }
        }
        this.verifyOtpLoad = false;
      }
    })
  }

  clearForgotPasswordForm() {
    this.email_id.reset();
    this.otp.reset();
      
    this.loading = false;
    this.otpIsSent = false;
    this.sendOtpLoad = false;
    this.otpInvalid = false;
    this.verifyOtpLoad = false;
    this.errorPopup = false;

    this.step = 0;
    this.timer = 0;
    this.incorrectOTPAttempts = 0;
    this.errorMessage = '';
  }

  navigateToLogin() {
    if (this.userType == INTERVIEWER) {
      this.router.navigate(['/b2c/interviewer/login'])
    };
    if (this.userType == CONSOLE_ADMIN) {
      this.router.navigate(['/ib/console/login'])
    };
    if (this.userType.includes(CONSOLE_OPS)) {
      this.router.navigate(['/ib/console/login'])
    };
    if (this.userType == B2B_ADMIN) {
      this.router.navigate(['/b2b/admin/login'])
    };
    if (this.userType == B2B_INTERVIEWER) {
      this.router.navigate(['/b2b/interviewer/login'])
    };
    if (this.userType == B2B_CANDIDATE) {
      this.router.navigate(['/b2b/candidate/login'])
    };
    if (this.userType == B2C_CANDIDATE) {
      this.router.navigate(['/b2c/candidate/login'])
    };
  }

  getOrganizationSlug() {
    let slug;
    if(this.organization) {
      console.log("Got Organization Succesfully in ngOninit, fetching Slug from Organization Details which fetched from DB")
      slug = this.organization.organization_slug
      console.log("Got Organization Slug is: ", slug)
    } else {
      console.log("Organization isn't got properly in ngOninit, fetching Slug from Domain")
      if (!environment.production) {
        slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
        console.log("Set Org Slug: ", slug)
      } else {
        slug = window.location.origin.split('https://')[1].split('.')[0];
      }
    }
    this.org_slug = slug;
    return slug;
  }
}