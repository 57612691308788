<div class="container h-100vh">
  <div class="d-flex align-items-center justify-content-center h-100">
    <div style="width: 461px !important;" class="card auth-form-shadow px-5 py-4 d-flex flex-column gap-3 bg-white rounded">
      <div class="mx-auto">
        <img class='mw-100' height="46px"
          *ngIf="organization?.organization_logo_key && organization?.organization_slug !== 'ib'"
          src="{{storageEndpoint}}/storage/get/profilePic/{{organization?.organization_logo_key}}" alt="Logo">
        <div class="my-auto" *ngIf="!organization?.organization_logo_key || organization?.organization_slug === 'ib'">
          <app-icons svgName="ib-logo"></app-icons>
        </div>
      </div>
      <div class="mt-3">
        <div class="row">
          <div class="col">
            <app-button btnSize="small" btnText="Sign in via Google" btnType="brand_light" [hasBtnText]="true"
              [hasIcon]="true" iconPath="/assets/icons/google.svg" (click)="loginWithOAuth('google')"></app-button>
          </div>
          <div class="col d-flex justify-content-end"> <app-button btnSize="small" btnText="Sign in via Linkedin"
              btnType="brand_light" [hasBtnText]="true" [hasIcon]="true" iconPath="/assets/icons/linkedin.svg"
              (click)="loginWithOAuth('linkedin')"></app-button>
          </div>
        </div>
        <div class="mt-2 w-100">
          <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Email"
            inputPlaceholder="e.g. mrnobody@myorg.com" [hasPrefixIcon]="false" [isDisabled]="otpIsSent"
            [isReadOnly]="otpIsSent" [hasFormControl]="true" inputType="text" [control]="email_id" />
          <p *ngIf="emailInvalid" class="font-12 text-error-500">Please enter a valid email-id</p>
        </div>
        <div class="mt-2" *ngIf="!isOTPLogin">
          <div class="p-input-icon-right w-100 ib-pb-8">
            <app-input class='w-100' inputSize="large" [hasInputLabel]="true" inputLabel="Password"
              [hasPrefixIcon]="false" [isDisabled]="false" [isReadOnly]="false" [hasFormControl]="true"
              [type]="!showPassword ? 'password' : 'text'" [control]="password" />
            <i class="pi pi-eye{{showPassword ? '' : '-slash'}} mt-1" (click)="showPassword = !showPassword"></i>
          </div>
        </div>
        <div class="mt-2" *ngIf="isOTPLogin">
          <div class="mt-2 row">
            <div class="col-7">
              <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="OTP" inputPlaceholder="OTP"
                [hasPrefixIcon]="false" [isDisabled]="!otpIsSent" [isReadOnly]="!otpIsSent" [hasFormControl]="true"
                inputType="text" [control]="otp" />
            </div>
            <div class="col-5 d-flex justify-content-end align-items-end">
              <app-button class="lh-24" btnSize="regular"
                [btnText]="timer > 0 ? 'Resend in '  + timer + 's' : 'Request OTP'" btnType="brand_light"
                [isDisabled]="timer > 0" [isLoading]="sendOTPEmailLoad" [hasBtnText]="true" (click)="loginWithOTP()">
              </app-button>
            </div>
          </div>
          <div class="row ib-mt-4">
            <div class="col">
              <div *ngIf="otpInvalid" class="text-start ib-pl-4">
                <p class="font-12 text-error-500">Invalid OTP[{{incorrectOTPAttempts}}]</p>
              </div>
            </div>
            <div class="col">
              <div *ngIf="otpIsSent" class="text-end ib-pr-12">
                <p class="font-12 text-success-500">OTP sent to mail!</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex mt-2 justify-content-between">
          <a class="link-secondary pointer" role="link" (click)="isOTPLogin = !isOTPLogin">Login using {{isOTPLogin ?
            'Password' : 'OTP'}}</a>
          <a class="link-secondary" *ngIf="!isOTPLogin" [routerLink]="user_forgot_password_route[userType]">Forgot
            Password?</a>
        </div>
        <div class="mt-3 w-100">
          <app-button class="w-100" btnSize="regular" btnText="Login" btnType="brand" [hasBtnText]="true"
            [isLoading]="loginLoad" (click)="isOTPLogin ? validateOTPLogin(): loginWithPassword()">
          </app-button>
        </div>

        <div *ngIf="userType == 'ib-interviewer' || userType == 'b2c-candidate'"
          class="mt-4 text-secondary font-small text-center">
          Don't have an account?
          <a
            [routerLink]="userType == 'ib-interviewer'? '/b2c/interviewer/register' : '/b2c/candidate/register'">Register</a>
        </div>
      </div>

      <!-- Error Popup -->
      <div *ngIf="errorPopup">
        <p-dialog contentStyleClass="b-10px" [(visible)]="errorPopup" [draggable]="false" [resizable]="false"
          [modal]="true" [closable]="false" [showHeader]="false">
          <p class="ib-mt-20">
            {{errorMessage}}
          </p>
          <app-button class="w-100" btnSize="regular" btnText="Close" btnType="brand" [hasBtnText]="true"
            (click)="clearLoginForm()">
          </app-button>
        </p-dialog>
      </div>
    </div>
  </div>
</div>