import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @HostListener('mouseenter') onHover() {
    switch (this.btnType) {
      case 'brand':
        this.btnColors.brand = 'bg-brand-600 text-white';
        break;
      case 'brand_light':
        this.btnColors.brand_light = 'bg-brand-75 text-brand-500';
        break;
      case 'success':
        this.btnColors.success = 'bg-success-600 text-white';
        break;
      case 'success_light':
        this.btnColors.success_light = 'bg-success-100 text-success-800';
        break;
      case 'warning':
        this.btnColors.warning = 'bg-warning-600 text-white';
        break;
      case 'warning_light':
        this.btnColors.warning_light = 'bg-warning-100 text-warning-800';
        break;
      case 'error':
        this.btnColors.error = 'bg-error-600 text-white';
        break;
      case 'error_light':
        this.btnColors.error_light = 'bg-error-100 text-error-800';
        break;
      case 'dark':
        this.btnColors.dark = 'bg-gray-700 text-white';
        break;
    }
  }

  @HostListener('mouseleave') onHoverLeave() {
    switch(this.btnType) {
      case 'brand':
        this.btnColors.brand = 'bg-brand-500 text-white';
        break;
      case 'brand_light':
        this.btnColors.brand_light = 'bg-brand-50 text-brand-500';
        break;
      case 'success':
        this.btnColors.success = 'bg-success-500 text-white';
        break;
      case 'success_light':
        this.btnColors.success_light = 'bg-success-50 text-success-700';
        break;
      case 'warning':
        this.btnColors.warning = 'bg-warning-500 text-white';
        break;
      case 'warning_light':
        this.btnColors.warning_light = 'bg-warning-50 text-warning-700';
        break;
      case 'error':
        this.btnColors.error = 'bg-error-500 text-white';
        break;
      case 'error_light':
        this.btnColors.error_light = 'bg-error-50 text-error-700';
        break;
      case 'dark':
        this.btnColors.dark = 'bg-gray-900 text-white';
        break;
    }
  }

  @HostListener('mousedown') isActive() {
    switch (this.btnType) {
      case 'brand':
        this.btnColors.brand = 'bg-brand-700 text-white';
        break;
      case 'brand_light':
        this.btnColors.brand_light = 'bg-brand-100 text-brand-500';
        break;
      case 'success':
        this.btnColors.success = 'bg-success-700 text-white';
        break;
      case 'success_light':
        this.btnColors.success_light = 'bg-success-200 text-success-800';
        break;
      case 'warning':
        this.btnColors.warning = 'bg-warning-700 text-white';
        break;
      case 'warning_light':
        this.btnColors.warning_light = 'bg-warning-200 text-warning-800';
        break;
      case 'error':
        this.btnColors.error = 'bg-error-700 text-white';
        break;
      case 'error_light':
        this.btnColors.error_light = 'bg-error-200 text-error-800';
        break;
      case 'dark':
        this.btnColors.dark = 'bg-gray-900 text-white';
        break;
    }
  }

  @HostListener('mouseup') isInActive() {
    switch (this.btnType) {
      case 'brand':
        this.btnColors.brand = 'bg-brand-500 text-white';
        break;
      case 'brand_light':
        this.btnColors.brand_light = 'bg-brand-50 text-brand-500';
        break;
      case 'success':
        this.btnColors.success = 'bg-success-500 text-white';
        break;
      case 'success_light':
        this.btnColors.success_light = 'bg-success-50 text-success-700';
        break;
      case 'warning':
        this.btnColors.warning = 'bg-warning-500 text-white';
        break;
      case 'warning_light':
        this.btnColors.warning_light = 'bg-warning-50 text-warning-700';
        break;
      case 'error':
        this.btnColors.error = 'bg-error-500 text-white';
        break;
      case 'error_light':
        this.btnColors.error_light = 'bg-error-50 text-error-700';
        break;
      case 'dark':
        this.btnColors.dark = 'bg-gray-700 text-white';
        break;
    }
  }

  @Input({ required: true }) hasBtnText: boolean = false;
  @Input({ required: true }) btnText: string = '';
  @Input({ required: false }) hasIcon: boolean = false;
  @Input({ required: false }) iconPath: string = '';
  @Input({ required: true }) btnType: 'brand' | 'brand_light' | 'success' | 'success_light' | 'error' | 'error_light' | 'warning' | 'warning_light' | 'dark'  = 'brand';
  @Input({ required: true }) btnSize: 'small' | 'regular' | 'large' = 'regular'; // Acceptable values: small, regular, large.
  @Input({ required: false }) isLoading: boolean = false;
  @Input ({ required: false }) isDisabled: boolean = false;
  @Input ({ required: false }) class: string = '';
  @Input ({ required: false }) hasIconFromIconComponent: boolean = false;
  @Input ({ required: false }) iconAlignment: 'left' | 'right' = 'right';



  @Output('click') emitter: EventEmitter<any> = new EventEmitter<any>();

  btnColors: any = {
    brand: "bg-brand-500 text-white",
    brand_light: "bg-brand-50 text-brand-500",
    success: "bg-success-500 text-white",
    success_light: "bg-success-50 text-success-700",
    error: "bg-error-500 text-white",
    error_light: "bg-error-50 text-error-700",
    warning: "bg-warning-500 text-white",
    warning_light: "bg-warning-50 text-warning-700",
    dark: "bg-gray-900 text-white"
  };

  btnSizes: any = {
    small: "font-12 lh-20",
    regular: "font-14 lh-20",
    large: "font-16 lh-24"
  }

}
