import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { build } from 'src/environments/info.timestamp';
import { release } from 'src/environments/info.release';

if (environment.production) {
  enableProdMode();
}

// Build info
console.log(
  '#AppSpec',
  '\n Release Version: ' + release.version,
  '\n Release Codename: ' + release.codename,
  '\n Build Timestamp: ' + build.timestamp,
  '\n Build Environment: ' + environment.name
)

// Conditional disabling of console logs
if (!environment.logs) {
  if (window) {
    window.console.log = function () { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
