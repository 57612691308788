import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INTERVIEWER, CONSOLE_ADMIN, B2B_ADMIN, B2B_INTERVIEWER, B2B_CANDIDATE, B2C_CANDIDATE, CONSOLE_OPS } from '../../general/constants/app-constants';
import { TokenStorageService } from 'src/app/core/services/common/token-storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Provider } from '@supabase/supabase-js';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { environment } from 'src/environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  userType: string = this.route.snapshot.data['user_role']

  email_id = new FormControl('', [Validators.email]);
  password = new FormControl('');
  otp = new FormControl('');

  errorPopup: boolean = false;
  errorMessage: string = '';

  loginLoad: boolean = false;
  showPassword: boolean = false;
  otpIsSent: boolean = false;
  sendOTPEmailLoad: boolean = false;
  isOTPLogin: boolean = false;
  fetchingUsers: boolean = false;

  storageEndpoint = environment.storage_endpoint;
  organization: any;
  timer = 0;
  incorrectOTPAttempts = 0;
  otpInvalid = false;
  org_slug = '';
  emailInvalid: boolean = false;

  user_forgot_password_route: any = {
    "b2b-admin": "/b2b/admin/forgot-password",
    "b2b-candidate": "/b2b/candidate/forgot-password",
    "b2b-interviewer": "/b2b/interviewer/forgot-password",
    "ib-console-admin": "/ib/console/forgot-password",
    "ib-ops-l1": "/ib/console/forgot-password",
    "ib-interviewer": "/b2c/interviewer/forgot-password",
    "b2c-candidate": "/b2c/candidate/forgot-password"
  };

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public storageService: TokenStorageService,
    public authService: AuthService,
    public adminService: AdminService,
    public http: HttpClient
  ) { }

  ngOnInit(): void {
    //Clear all local storage variable before login to application
    console.log('Clearing Localstorage!!!')
    this.storageService.clearLocalStorage();

    //Saving user type in local storageService
    this.storageService.saveUserRole(this.userType);
    this.storageService.saveLastActive(Date.now());

    if (!this.storageService.getOrganization()) {
      let org_slug = ''
      if (!environment.production) {
        org_slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
        console.log("Set Org Slug: ", org_slug)
      } else {
        org_slug = window.location.origin.split('https://')[1].split('.')[0];
      }
      this.authService.getOrganization(org_slug).subscribe({
        next: (data) => {
          console.log(data, "getOrganization Results")
          this.organization = data.data?.organizations?.[0];
          this.storageService.saveOrganization(JSON.stringify(data.data?.organizations?.[0]))
        },
        error: (err) => {
          console.log("Error while fetching Organization Details:\n", err)
        }
      })
    } else {
      this.organization = JSON.parse(this.storageService.getOrganization());
    }

    // Validate Google Auth if redirected after Google Authentication
    if (this.storageService.getGoogleAuthRedirect() == 'true') {
      this.loginLoad = true;
      this.validateOAuthLogin();
    }
  }


  // Login with Password
  async loginWithPassword() {
    this.loginLoad = true;

    const email: string = this.email_id.value || '';
    const password: string = this.password.value || '';
    if (email === '' || password === '') {
      console.error('Invalid Email or Password')
      this.errorPopup = true;
      this.errorMessage = "Invalid Email or Password!. Please provide a valid Email and Password."
    }

    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

    this.http.post(environment.auth_endpoint + '/auth/login', {
      email: email,
      password: password,
      role: this.storageService.getUserRole(),
      organization_slug: this.org_slug
    }).subscribe({
      next: (res: any) => {
        console.log("Response from Backend's Login", res);
        this.setUserDetailsAndNavigate(res);
      },
      error: (err) => {
        if (err.error.message === 'Your access is restricted. Contact the administrator') {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "Your access is restricted. Contact the administrator";
          this.errorPopup = true;
        } else if (err.error.message === 'You do not have an account with provide email!. Please try with a registered email' || err.error.message === "Account does not exist for the provided email. Please use a registered email.") {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "Account does not exist for the provided email. Please use a registered email.";
          this.errorPopup = true;
        } else if (err.error.message === 'Invalid login credentials! Please try again') {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "Invalid login credentials! Please try again";
          this.errorPopup = true;
        } else {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "An error occurred while trying to Login! Please try again.";
          this.errorPopup = true;
        }
        this.loginLoad = false;
      }
    })
  }

  loginWithOTP() {
    this.sendOTPEmailLoad = true;
    this.http.post(environment.auth_endpoint + '/auth/send-otp', {
      email: this.email_id.value
    }).subscribe({
      next: (res) => console.log(res),
      error: (err) => {
        if (err.error.message) {
          console.log("Error is raised while trying to send OTP with following message: ", err.error.message)
          this.emailInvalid = true;
        } else {
          console.log("Error is raised while trying to send OTP: ", err)
          this.errorPopup = true;
          this.errorMessage = "An Unknown Error Occurred! Please try again."
        }
        this.otpIsSent = false;
        this.email_id.reset();

        this.sendOTPEmailLoad = false;
      },
      complete: () => {
        this.emailInvalid = false;
        this.otpIsSent = true;
        this.otp.reset();
        this.timer = 60;
        let time = setInterval(() => {
          this.timer -= 1
          if (this.timer <= 0) {
            clearInterval(time)
          }
        }, 1000);
        this.sendOTPEmailLoad = false;
      }
    })
  }

  validateOTPLogin() {
    this.loginLoad = true;

    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

    this.http.post(environment.auth_endpoint + '/auth/verify-otp', {
      email: this.email_id.value,
      otp: this.otp.value,
      role: this.storageService.getUserRole(),
      organization_slug: this.org_slug,
      functionality: 'login'
    }).subscribe({
      next: (res: any) => {
        console.log("Response from Backend's Login", res);
        this.setUserDetailsAndNavigate(res);
      },
      error: (err) => {
        if (err.error.message === 'Your access is restricted. Contact the administrator') {
          console.log("Error is raised while trying to verify OTP with following message: ", err.error);
          this.errorMessage = "Your access is restricted. Contact the administrator";
          this.errorPopup = true;
        } else if (err.error.message === 'You do not have an account with provide email!. Please try with a registerd email' || err.error.message === "Account does not exist for the provided email. Please use a registered email.") {
          console.log("Error is raised while trying to verify OTP with following message: ", err.error);
          this.errorMessage = "Account does not exist for the provided email. Please use a registered email.";
          this.errorPopup = true;
        } else if (err.error.message === 'Invalid OTP') {
          this.otpInvalid = true;
          this.incorrectOTPAttempts = this.incorrectOTPAttempts + 1;
          if (this.incorrectOTPAttempts >= 3) {
            this.errorMessage = "You've entered Incorrect OTP 3 Times!. Please try again.";
            this.errorPopup = true;
          }
        } else {
          console.log("Error is raised while trying to verify OTP with following message: ", err.error);
          this.errorMessage = "An error occurred while trying to Login!. Please try again.";
          this.errorPopup = true;
        }
        this.loginLoad = false;
      }
    });
  }

  // Login with OAuth
  async loginWithOAuth(type: Provider) {
    this.loginLoad = true;
    this.storageService.saveGoogleAuthRedirect('true');
    try {
      const { data, error } = await this.authService.supabaseAuth.auth.signInWithOAuth({
        provider: type,
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          redirectTo: window.location.origin
        }
      });
      if (error) {
        new Error(error.message)
      } else {
        console.log("Response from OAuth Login", data);
        this.loginLoad = false;
      }
    } catch (err) {
      console.log('Error while trying to login with OTP:\n', err)
      this.errorPopup = true;
      this.errorMessage = "An unexpected error occured!. Please try again."
      this.loginLoad = false;
    }
  }

  // Validate OAuth Login After Redirection
  async validateOAuthLogin() {
    this.loginLoad = true;
    const { data, error }: any = await this.authService.supabaseAuth.auth.getSession();

    //Getting Organization Slug
    this.org_slug = this.getOrganizationSlug();
    console.log("Slug got from getOrganizationSlug(): ", this.org_slug)

    if (data.session) {
      this.storageService.saveAccessToken(data?.session.access_token);
      this.storageService.saveRefreshToken(data?.session.refresh_token);
      this.http.post(environment.auth_endpoint + '/auth/login-oauth', {
        role: this.storageService.getUserRole(),
        organization_slug: this.org_slug,
        jwt: data?.session.access_token,
      }).subscribe({
        next: (res: any) => {
          console.log("Response from Backend's Login", res);
          this.setUserDetailsAndNavigate(res);
        },
        error: (err) => {
          if (err.error.message) {
            console.log("Error is raised while trying to logging in through OAuth with following message: ", err.error.message)
            this.errorPopup = true;
            this.errorMessage = err.error.message;
          } else {
            console.log("Error is raised while trying to logging in through OAuth: ", err)
            this.errorPopup = true;
            this.errorMessage = "An Unknown Error Occurred! Please try again."
          }
          this.loginLoad = false;
        }
      })
    } else if (error) {
      console.log('Error while trying to register with OAuth:\n', error.message)
      this.errorPopup = true;
      this.errorMessage = "An unexpected error occured! Please try again."
      this.loginLoad = false;
    } else {
      console.log("In Else")
      this.errorPopup = true;
      this.errorMessage = "An unexpected error occured! Please try again."
      this.loginLoad = false;
    }
    this.storageService.clearGoogleAuthRedirect();
  }

  setUserDetailsAndNavigate(result: any) {
    console.log("setUserDetailsAndNavigate is called: ", result)
    if (!result.success) {
      this.loginLoad = false;
      this.errorPopup = true;
      this.errorMessage = "An error occurred while trying to Login!. Please try again."
    }
    else {
      this.storageService.saveAccessToken(result.data.access_token);
      this.storageService.saveRefreshToken(result.data.refresh_token);
      this.storageService.saveUserRole(result.data.user_role)
      localStorage.setItem('ib-user-id', result.data.user_organization_id);
      this.storageService.saveUserStatus(result.data.status);
      this.storageService.saveUserTimeZone(result.data.timezone)
      this.authService.loginPreformedObserver.next('');
      switch (this.userType) {
        case CONSOLE_ADMIN: this.router.navigate(['/ib/console/dashboard']);
          break
        case CONSOLE_OPS: this.router.navigate(['/ib/console/dashboard']);
          break
        case INTERVIEWER: this.router.navigate(['/b2c/interviewer/dashboard']);
          break
        case B2B_ADMIN: this.router.navigate(['/b2b/admin/dashboard']);
          break
        case B2B_INTERVIEWER: this.router.navigate(['/b2b/interviewer/dashboard']);
          break
        case B2B_CANDIDATE: this.router.navigate(['/b2b/candidate/dashboard']);
          break
        case B2C_CANDIDATE: this.router.navigate(['/b2c/candidate/dashboard']);
      }

    }
  }

  clearLoginForm() {
    this.email_id.reset();
    this.otp.reset();
    this.password.reset();
    this.errorPopup = false;
    this.errorMessage = '';
    this.otpIsSent = false;
    this.incorrectOTPAttempts = 0;
  }

  getOrganizationSlug() {
    let slug;
    if(this.organization) {
      console.log("Got Organization Succesfully in ngOninit, fetching Slug from Organization Details which fetched from DB")
      slug = this.organization.organization_slug
      console.log("Got Organization Slug is: ", slug)
    } else {
      console.log("Organization isn't got properly in ngOninit, fetching Slug from Domain")
      if (!environment.production) {
        slug = window.location.origin.includes('localhost:4321') ? 'acme' : 'ib';
        console.log("Set Org Slug: ", slug)
      } else {
        slug = window.location.origin.split('https://')[1].split('.')[0];
      }
    }
    return slug;
  }
}
