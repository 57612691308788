<div *ngIf="step === 1">
    @if(success) {
    <div style="height: 100px;" class="mx-auto text-center">
        <img class='mh-100 mw-100' src="/assets/icons/check-circle-success.svg" alt="Success">
        <div class="text-success">Password updated successfully!</div>
    </div>
    <div class="w-100">
        <app-button class="w-100" btnSize="regular" btnText="Go back to login page" btnType="brand" [hasBtnText]="true"
            [routerLink]="loginLink">
        </app-button>
    </div>
    }
    @else {
    <div class="text-center">
        <label class="text-primary">Reset Password</label>
    </div>
    <div class="mt-3">
        <div>
            <p class="font-12 text-secondary">Email</p>
            <div class="row">
                <p class="col-8 m-0 font-14">{{email_id}}</p>
                <app-new-badge class="col-4 d-flex justify-content-end mb-4" text="Verified" badgeType="regular"
                    badgeColor="success_filled" [hasIcon]="true"></app-new-badge>
            </div>
        </div>

        <div class="p-field">
            <div class="p-input-icon-right w-100 ib-pb-8">
                <app-input class='w-100' inputSize="large" [hasInputLabel]="true" inputLabel="Password"
                    [hasPrefixIcon]="false" [isDisabled]="false" [isReadOnly]="false" [hasFormControl]="true"
                    [type]="!showPassword ? 'password' : 'text'" [control]="password" />
                <i class="pi pi-eye{{showPassword ? '' : '-slash'}} mt-1" (click)="showPassword = !showPassword"></i>
            </div>
            @if(password.errors) {
            <div class="text-secondary font-small">Password must contain</div>
            <ul class="text-secondary font-small">
                <li class="text-secondary font-small">At least 6 characters</li>
                <li class="text-secondary font-small">At least 1 upper case letter [A-Z]</li>
                <li class="text-secondary font-small">At least 1 lower case letter [a-z]</li>
                <li class="text-secondary font-small">At least 1 number [1-9]</li>
            </ul>
            }
        </div>
        <div class="p-field">
            <div class="p-input-icon-right w-100">
                <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Confirm Password"
                    [hasPrefixIcon]="false" [isDisabled]="false" [isReadOnly]="false" [hasFormControl]="true"
                    [type]="!showCPassword ? 'password' : 'text'" [control]="confirmPassword"></app-input>
                <i class="pi pi-eye{{showCPassword ? '' : '-slash'}} mt-1" (click)="showCPassword = !showCPassword"></i>
            </div>
            @if(password.value !== confirmPassword.value) {
            <div class="text-secondary font-small">Password & Confirm password must match</div>
            }
        </div>
    </div>
    <div class="w-100 ib-mt-16">
        <app-button class="w-100" btnSize="regular" btnText="Update password" btnType="brand" [hasBtnText]="true"
            [isDisabled]="!email_id || password.invalid || confirmPassword.invalid" [isLoading]="loading"
            (click)="resetPassword()">
        </app-button>
    </div>
    }
</div>

<div *ngIf="step === 2" class="text-center">
    <app-icons class="text-success-600" style="font-size: 80px;" svgName="check-circle"></app-icons>
    <p class="text-success-600 font-18 ib-mb-24">Password Resetted successfully!</p>
    <p class="font-16">Redirecting in few seconds...</p>
</div>

<div *ngIf="errorPopup">
    <p-dialog contentStyleClass="b-10px" [(visible)]="errorPopup" [draggable]="false" [resizable]="false" [modal]="true"
        [closable]="false" [showHeader]="false">
        <p class="ib-mt-20">
            {{errorMessage}}
        </p>
        <app-button class="w-100" btnSize="regular" btnText="Back to Login page" btnType="brand" [hasBtnText]="true"
            (click)="router.navigate([this.loginLink])">
        </app-button>
    </p-dialog>
</div>