<div class="container h-100vh">
  <div class="d-flex align-items-center justify-content-center h-100">
    <div style="width: 461px !important;" class="card shadow light ib-px-48 ib-py-32 d-flex flex-column gap-3 bg-white rounded">

      <!-- IB Logo -->
      <div class="d-flex">
        <app-icons svgName="arrow-left" class="me-3" alt="Back" (click)="step = 0" *ngIf="step === 1"></app-icons>
        <div class="mx-auto">
          <app-icons svgName="ib-logo"></app-icons>
        </div>
      </div>

      <!-- Step 0 -->
      <div class="mt-3" *ngIf="step === 0">
        <div class="row">
          <div class="col">
            <app-button btnSize="small" btnText="Sign up via Google" btnType="brand_light" [hasBtnText]="true"
            [hasIcon]="true" iconPath="/assets/icons/google.svg" (click)="registerWithOAuth('google')"></app-button>
          </div>
          <div class="col d-flex justify-content-end">
            <app-button btnSize="small" btnText="Sign up via Linkedin" btnType="brand_light"
            [hasBtnText]="true" [hasIcon]="true" iconPath="/assets/icons/linkedin.svg" (click)="registerWithOAuth('linkedin')"></app-button>
          </div> 
        </div>

        <div class="mt-2 w-100">
          <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Full Name"
            inputPlaceholder="e.g.Michael" [hasPrefixIcon]="false" [isDisabled]="otpIsSent" [isReadOnly]="otpIsSent"
            [hasFormControl]="true" inputType="text" [control]="name" />
        </div>
        <div class="mt-2 w-100">
          <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Email"
            inputPlaceholder="e.g. mrnobody@myorg.com" [hasPrefixIcon]="false" [isDisabled]="otpIsSent"
            [isReadOnly]="otpIsSent" [hasFormControl]="true" inputType="text" [control]="email_id" />
          <p *ngIf="emailInvalid" class="font-12 text-error-500">Please enter a valid email-id</p>
        </div>

        <div class="mt-2 row">
          <div class="col-7">
            <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="OTP" inputPlaceholder="OTP"
              [hasPrefixIcon]="false" [isDisabled]="!otpIsSent" [isReadOnly]="!otpIsSent" [hasFormControl]="true"
              inputType="text" [control]="otp" />
          </div>
          <div class="col-5 d-flex justify-content-end align-items-end">
            <app-button class="lh-24" btnSize="regular" [btnText]="timer > 0 ? 'Resend in '  + timer + 's' : 'Request OTP'" btnType="brand_light" [isDisabled]="timer > 0"
              [isLoading]="sendOtpLoad" [hasBtnText]="true" (click)="sendOtp()">
            </app-button>
          </div>
        </div>
        <div class="row ib-mt-4">
          <div class="col">
            <div *ngIf="otpInvalid" class="text-start ib-pl-4">
              <p class="font-12 text-error-500">Invalid OTP[{{incorrectOTPAttempts}}]</p>
            </div>
          </div>
          <div class="col">
            <div *ngIf="otpIsSent" class="text-end ib-pr-12">
              <p class="font-12 text-success-500">OTP sent to mail!</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 w-100" *ngIf="step === 0">
        <app-button class="w-100" btnSize="regular" btnText="Continue" btnType="brand" [isLoading]="verifyOtpLoad || registerLoad" [isDisabled]="this.otp.value === ''"
          [hasBtnText]="true" (click)="verifyOtp()">
        </app-button>
      </div>

      <!-- Step 1 -->
      <div class="mt-3" *ngIf="step === 1">
        <div>
          <p class="font-12 text-secondary">Email</p>
          <div class="row">
            <p class="col-8 m-0 font-14">{{email_id.value}}</p>
            <app-new-badge class="col-4 d-flex justify-content-end mb-4" text="Verified" badgeType="regular"
              badgeColor="success_filled" [hasIcon]="true"></app-new-badge>
          </div>
        </div>


        <app-message-alert *ngIf="isExistingEmail" alertText="The Password you Set will be used for all Accounts Registered with the above Email"
          alertType="info"></app-message-alert>

        <div class="p-field">
          <div class="p-input-icon-right w-100 ib-pb-8">
            <app-input class='w-100' inputSize="large" [hasInputLabel]="true" inputLabel="Password" [hasPrefixIcon]="false"
              [isDisabled]="false" [isReadOnly]="false" [hasFormControl]="true" [type]="!showPassword ? 'password' : 'text'" [control]="password" />
            <i class="pi pi-eye{{showPassword ? '' : '-slash'}} mt-1" (click)="showPassword = !showPassword"></i>
          </div>
          @if(password.errors) {
          <div class="text-secondary font-small">Password must contain</div>
          <ul class="text-secondary font-small">
            <li class="text-secondary font-small">At least 6 characters</li>
            <li class="text-secondary font-small">At least 1 upper case letter [A-Z]</li>
            <li class="text-secondary font-small">At least 1 lower case letter [a-z]</li>
            <li class="text-secondary font-small">At least 1 number [1-9]</li>
          </ul>
          }
        </div>
        <div class="p-field">
          <div class="p-input-icon-right w-100">
            <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Confirm Password" [hasPrefixIcon]="false"
              [isDisabled]="false" [isReadOnly]="false" [hasFormControl]="true" [type]="!showCPassword ? 'password' : 'text'" [control]="confirmPassword"></app-input>
            <i class="pi pi-eye{{showCPassword ? '' : '-slash'}} mt-1" (click)="showCPassword = !showCPassword"></i>
          </div>
          @if(password.value !== confirmPassword.value) {
          <div class="text-secondary font-small">Password & Confirm password must match</div>
          }
        </div>
      </div>
      <div class="mt-3 w-100" *ngIf="step === 1">
        <app-button class="w-100" btnSize="regular" btnText="Register" btnType="brand" [hasBtnText]="true"
          [isLoading]="registerLoad" (click)="registerWithEmailAndPassword()">
        </app-button>
      </div>

      <div *ngIf="step === 2" class="text-center">
        <app-icons class="text-success-600" style="font-size: 80px;" svgName="check-circle"></app-icons>
        <p class="text-success-600 font-18 ib-mb-24">Registered successfully!</p>
        <p class="font-16">Redirecting in few seconds...</p>
      </div>

      <div *ngIf="step === 3">
        <div>
          <p class="font-12 text-secondary">Email</p>
          <div class="row">
            <p class="col-8 m-0 font-14">{{email_id.value}}</p>
          </div>
        </div>
        <app-message-alert alertText="An account already exists with this email address!"
          alertType="info"></app-message-alert>
        <div class="mt-3 w-100">
          <app-button class="w-100" btnSize="regular" btnText="Proceed to Login page" btnType="brand"
            [hasBtnText]="true"
            [routerLink]="userType == 'ib-interviewer'? '/b2c/interviewer/login' : '/b2c/candidate/login'">
          </app-button>
        </div>
      </div>
      <div *ngIf="(userType == 'ib-interviewer' || userType == 'b2c-candidate') && step < 2"
        class="mt-4 text-secondary font-small text-center">
        Already have an account?
        <a [routerLink]="userType == 'ib-interviewer'? '/b2c/interviewer/login' : '/b2c/candidate/login'">Login</a>
      </div>
    </div>
  </div>

  <!-- Error Popup -->
  <div *ngIf="errorPopup">
    <p-dialog contentStyleClass="b-10px" [(visible)]="errorPopup" [draggable]="false" [resizable]="false"
      [modal]="true" [closable]="false" [showHeader]="false">
      <p class="ib-mt-20">
        {{errorMessage}}
      </p>
      <app-button *ngIf="!isExistingUserInSupabase" class="w-100" btnSize="regular" btnText="Back to Register page" btnType="brand" [hasBtnText]="true"
        (click)="clearRegisterForm()">
      </app-button>
      <app-button *ngIf="isExistingUserInSupabase" class="w-100" btnSize="regular" btnText="Register" btnType="brand" [hasBtnText]="true"
        [isLoading]="registerLoad" (click)="registerWithEmailAndPassword()">
      </app-button>
    </p-dialog>
  </div>
</div>