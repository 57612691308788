@if (hasInputLabel) {
    <label class="input-label font-weight-400 text-secondary font-12">{{inputLabel}}</label>
}
<p-inputGroup class="input {{inputSize}} d-flex align-items-center font-14" [ngClass]="[(hasFormControl && control?.touched && control?.errors?.required) ? 'border-error br-6px' : 'border-default br-6px', (isDisabled ? 'bg-gray-50 border-disabled br-6px' : 'bg-white border-default br-6px')]">
    @if (hasPrefixIcon) {
        <p-inputGroupAddon>
            <div class="d-flex align-items-center">
                @switch (inputSize) {
                    @case ('small') {
                        <img [src]="prefixIconPath" class="border-rounded" height="16px" width="16px">
                    }
                    @case ('regular') {
                        <img [src]="prefixIconPath" class="border-rounded" height="20px" width="20px">
                    }
                    @case ('large') {
                        <img [src]="prefixIconPath" class="border-rounded" height="24px" width="24px">
                    }
                }
            </div>
        </p-inputGroupAddon>
    }
    @if (hasFormControl) {
        <input class="lh-20" pInputText [type]="type" [placeholder]="inputPlaceholder" [disabled]="isDisabled" [readonly]="isReadOnly" [formControl]="control" (input)="handleEvent('input')" />
    }
    @else {
        <input class="lh-20" pInputText [type]="type" [placeholder]="inputPlaceholder" [disabled]="isDisabled" [readonly]="isReadOnly" [(ngModel)]="value" (input)="handleEvent('input')" />
    }
    @if (hasClearBtn) {
        <app-button btnSize="small" btnType="brand_light" [hasBtnText]="false" btnText="" class="clear-button d-flex align-items-center justify-content-center" [ngClass]="inputSize === 'large' ? 'font-20' : (inputSize === 'regular' ? 'font-16' : 'font-12')" icon="pi pi-times" (click)="handleEvent('clear')">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 12" fill="none">
                <path d="M2.55106 2.55106C2.59882 2.50317 2.65556 2.46518 2.71803 2.43926C2.78049 2.41334 2.84746 2.4 2.91509 2.4C2.98272 2.4 3.04969 2.41334 3.11216 2.43926C3.17463 2.46518 3.23137 2.50317 3.27913 2.55106L6.00013 5.27309L8.72114 2.55106C8.76895 2.50325 8.8257 2.46533 8.88816 2.43946C8.95062 2.41359 9.01757 2.40027 9.08518 2.40027C9.15278 2.40027 9.21973 2.41359 9.28219 2.43946C9.34465 2.46533 9.40141 2.50325 9.44921 2.55106C9.49702 2.59886 9.53494 2.65562 9.56081 2.71808C9.58668 2.78054 9.6 2.84749 9.6 2.91509C9.6 2.9827 9.58668 3.04965 9.56081 3.11211C9.53494 3.17457 9.49702 3.23132 9.44921 3.27913L6.72718 6.00014L9.44921 8.72114C9.49702 8.76895 9.53494 8.8257 9.56081 8.88816C9.58668 8.95062 9.6 9.01757 9.6 9.08518C9.6 9.15279 9.58668 9.21973 9.56081 9.28219C9.53494 9.34465 9.49702 9.40141 9.44921 9.44921C9.40141 9.49702 9.34465 9.53494 9.28219 9.56081C9.21973 9.58668 9.15278 9.6 9.08518 9.6C9.01757 9.6 8.95062 9.58668 8.88816 9.56081C8.8257 9.53494 8.76895 9.49702 8.72114 9.44921L6.00013 6.72718L3.27913 9.44921C3.23132 9.49702 3.17457 9.53494 3.11211 9.56081C3.04965 9.58668 2.9827 9.6 2.91509 9.6C2.84748 9.6 2.78054 9.58668 2.71808 9.56081C2.65562 9.53494 2.59886 9.49702 2.55106 9.44921C2.50325 9.40141 2.46533 9.34465 2.43946 9.28219C2.41359 9.21973 2.40027 9.15279 2.40027 9.08518C2.40027 9.01757 2.41359 8.95062 2.43946 8.88816C2.46533 8.8257 2.50325 8.76895 2.55106 8.72114L5.27309 6.00014L2.55106 3.27913C2.50317 3.23137 2.46518 3.17463 2.43926 3.11216C2.41334 3.04969 2.4 2.98272 2.4 2.91509C2.4 2.84746 2.41334 2.78049 2.43926 2.71803C2.46518 2.65556 2.50317 2.59882 2.55106 2.55106Z" fill="#97A1B2"/>
            </svg>
        </app-button>
    }
</p-inputGroup>
@if (hasFormControl && control?.touched && control?.errors?.required) {
    <div class="text-error-500 font-12 mt-1">
        {{ requiredErrorMsg }}
    </div>
}
@if (hasFormControl && control?.touched && control?.errors?.pattern) {
    <div class="text-error-500 font-12 mt-1">
        {{ patternErrorMsg }}
    </div>
}
