import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { ReactiveFormsModule } from '@angular/forms';

//Import of apollo client related dependencies
import { GraphQLModule } from './graphql.module';

import { AuthService } from './core/services/auth/auth.service';
import { AdminService } from './core/services/admin/admin.service';
import { CodeEditorModule } from '@ngstack/code-editor';
import { BaseComponentsModule } from './base-components/base-components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    GraphQLModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // CoreModule,
    HttpClientModule,
    CodeEditorModule.forRoot({
      editorVersion: '0.44.0'
    }),
    ReactiveFormsModule,
    BaseComponentsModule
  ],
  providers: [
    AuthService,
    AdminService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
