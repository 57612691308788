<div class="d-flex align-items-center font-12 font-weight-regular ib-p-8 {{alertColors[alertType]}}">
    <div class="ib-mr-8">
        @switch (alertType) {
            @case ('info') {
                <img src="/assets/icons/info.svg" alt="info icon" />
            }
            @case ('success') {
                <img src="/assets/icons/check-circle.svg" alt="success icon" />
            }
            @case('warning') {
                <img src="/assets/icons/triangle-danger.svg" alt="warning icon" />
            }
            @case('danger') {
                <img src="/assets/icons/alert.svg" alt="alert icon" />
            }
        }
    </div>
    <div>
        {{alertText}}
    </div>
</div>