import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';

export abstract class AuthData {

  GET_ORGANIZATION = gql`
  query getOrganization($organization_slug: String!) @cached(ttl: 60) {
    organizations(where: {organization_slug: {_eq: $organization_slug}}, limit: 1) {
      organization_id
      organization_slug
      organization_name
      organization_url
      organization_logo_name
      organization_logo_key
    }
  }  
  `;

  // organization_logo_key
  // organization_logo_name

  REGISTER = gql`
    mutation Register {
      register {
        message
      }
    }
  `;

  LOGIN = gql`
    query Login($organization_id: uuid!) {
      login(organization_id: $organization_id) {
        access_token
        refresh_token
        user_organization_id
        user_role
      }
    }
  `;

  REFRESH_TOKEN = gql`
    mutation RefreshToken($refresh_token: String = "") {
      refreshToken(refresh_token: $refresh_token) {
        access_token
        refresh_token
      }
    }
  `;

  abstract registerIB(): Observable<any>;
  abstract loginIB(): Observable<any>;
  abstract getOrganization(organization_slug: string): Observable<any>;
}
