<!-- Online -->
<ng-container *ngIf="online">
    <!-- Organization load state -->
    <ng-container *ngIf="getOrganizationLoad || getCurrentModuleLoad">
        <div class="container h-100vh">
            <div class="row d-flex align-items-center justify-content-center g-0 h-100">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Organization load complete state -->
    <ng-container *ngIf="!getOrganizationLoad && !getCurrentModuleLoad && !mobile">
        <ng-container *ngIf="httpError">
            <div class="container h-100vh">
                <div class="row d-flex align-items-center justify-content-center g-0 h-100">
                    <div class="col-sm-12 col-md-6">
        
                        <div class="row card shadow light g-0 justify-content-center p-5">
                            <div class="col-12 text-center">
                                <div class="text-danger font-large">
                                    Oops!
                                </div>
                                <div class="text-accent-blue font-large">
                                    Something's not right
                                </div>
                                <div class="text-secondary my-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                                    </svg>
                                </div>
                                <div class="text-secondary">
                                    Please try reloading the page. If the problem persists, reach us out via email.
                                </div>
                                <div class="mt-1">
                                    <app-new-badge text="help&#64;interviewbuddy.in" badgeColor="blue_filled" badgeType="regular"></app-new-badge>
                                    <!-- <span class="p-4px b-5px font-extra-small solid-secondary-badge">help&#64;interviewbuddy.in</span> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
        <router-outlet *ngIf="!httpError"></router-outlet>
    </ng-container>

    <!-- Mobile device -->
    <ng-container *ngIf="mobile">
        <div class="container h-100vh">
            <div class="row d-flex align-items-center justify-content-center g-0 h-100">
                <div class="col-sm-12 col-md-6">
    
                    <div class="row card shadow light g-0 justify-content-center p-5">
                        <div class="col-12 text-center">
                            <div class="text-accent-blue font-large">
                                <b>InterviewBuddy</b>
                                <br>
                                User Application
                            </div>
                            <div class="text-secondary my-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-window-x" viewBox="0 0 16 16">
                                    <path d="M2.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM4 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>
                                    <path d="M0 4a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v4a.5.5 0 0 1-1 0V7H1v5a1 1 0 0 0 1 1h5.5a.5.5 0 0 1 0 1H2a2 2 0 0 1-2-2V4Zm1 2h13V4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2Z"/>
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z"/>
                                </svg>
                            </div>
                            <div class="text-danger">
                                This web application is not designed to be used on mobile devices.
                            </div>
                            <div class="text-secondary">
                                Please use a compatible wide-screen device or switch on desktop-mode in your mobile browser to proceed.
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </ng-container>
    
</ng-container>

<!-- Offline -->
<ng-container *ngIf="!online">
    <div class="container h-100vh">
        <div class="row d-flex align-items-center justify-content-center g-0 h-100">
            <div class="col-sm-12 col-md-6">

                <div class="row card shadow light g-0 justify-content-center p-5">
                    <div class="col-12 text-center">
                        <div class="text-danger font-large">
                            We're unable to reach
                        </div>
                        <div class="text-accent-blue font-large">
                            <b>InterviewBuddy</b> Cloud
                        </div>
                        <div class="text-secondary my-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" class="bi bi-wifi-off" viewBox="0 0 16 16">
                                <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                            </svg>
                        </div>
                        <div class="text-secondary">
                            Please check your internet connection. If the problem persists, reach us out via email.
                        </div>
                        <div class="mt-1">
                            <app-new-badge text="help&#64;interviewbuddy.in" badgeColor="blue_filled" badgeType="regular"></app-new-badge>
                            <!-- <span class="p-4px b-5px font-extra-small solid-secondary-badge">help&#64;interviewbuddy.in</span> -->
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-container>

