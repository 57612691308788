<div class="container h-100vh">
    <div class="d-flex align-items-center justify-content-center h-100">
        <div style="width: 461px !important;" class="card shadow light px-5 py-4 d-flex flex-column gap-3 bg-white rounded">
            <div class="mx-auto">
                <img class='mw-100' height="46px"
                    *ngIf="organization?.organization_logo_key && organization?.organization_slug !== 'ib'"
                    src="{{storageEndpoint}}/storage/get/profilePic/{{organization?.organization_logo_key}}" alt="Logo">
                <div class="mx-auto" *ngIf="!organization?.organization_logo_key || organization?.organization_slug === 'ib'">
                    <app-icons svgName="ib-logo"></app-icons>
                </div>
            </div>
            <div *ngIf="step === 0">
                <div class="mt-1">
                    <label class="text-secondary">Please verify your email through OTP to continue.</label>
                </div>
                <div class="mt-2 w-100">
                    <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="Email"
                        inputPlaceholder="e.g. mrnobody@myorg.com" [hasPrefixIcon]="false" [isDisabled]="otpIsSent"
                        [isReadOnly]="otpIsSent" [hasFormControl]="true" inputType="text" [control]="email_id" />
                    <p *ngIf="emailInvalid" class="font-12 text-error-500">Please enter a valid email-id</p>
                </div>
                <div class="mt-2 row">
                    <div class="col-7">
                        <app-input class="w-100" inputSize="large" [hasInputLabel]="true" inputLabel="OTP"
                            inputPlaceholder="OTP" [hasPrefixIcon]="false" [isDisabled]="!otpIsSent"
                            [isReadOnly]="!otpIsSent" [hasFormControl]="true" inputType="text" [control]="otp" />
                    </div>
                    <div class="col-5 d-flex justify-content-end align-items-end">
                        <app-button class="lh-24" btnSize="regular"
                            [btnText]="timer > 0 ? 'Resend in '  + timer + 's' : 'Request OTP'" btnType="brand_light"
                            [isDisabled]="timer > 0" [isLoading]="sendOtpLoad" [hasBtnText]="true" (click)="sendOtp()">
                        </app-button>
                    </div>
                </div>
                <div class="row ib-mt-4">
                    <div class="col">
                        <div *ngIf="otpInvalid" class="text-start ib-pl-4">
                            <p class="font-12 text-error-500">Invalid OTP[{{incorrectOTPAttempts}}]</p>
                        </div>
                    </div>
                    <div class="col">
                        <div *ngIf="otpIsSent" class="text-end ib-pr-12">
                            <p class="font-12 text-success-500">OTP sent to mail!</p>
                        </div>
                    </div>
                </div>
                <div class="mt-3 w-100">
                    <app-button class="w-100" btnSize="regular" btnText="Continue" btnType="brand" [isLoading]="verifyOtpLoad" [isDisabled]="otp.value === ''"
                        [hasBtnText]="true" (click)="verifyOtp()">
                    </app-button>
                </div>
            </div>
            <div *ngIf="step === 1">
                <div *ngIf="!isExistingEmail">
                    <div class="mt-1">
                        <label class="text-secondary">There is no account with the following email!</label>
                        <label class="text-primary">{{email_id.value}}</label>
                        <app-button class="w-100 ib-mt-8" btnSize="regular" btnText="Continue to Login" btnType="brand"
                            [hasBtnText]="true" (click)="navigateToLogin()">
                        </app-button>
                    </div>
                </div>
                <div *ngIf="isExistingEmail">
                    <app-reset-password [email_id]="email_id.value!" [organization_slug]="org_slug"></app-reset-password>
                </div>
            </div>
        </div>            
    </div>

    <!-- Error Popup -->
    <div *ngIf="errorPopup">
        <p-dialog contentStyleClass="b-10px" [(visible)]="errorPopup" [draggable]="false" [resizable]="false"
            [modal]="true" [closable]="false" [showHeader]="false">
            <p class="ib-mt-20">
                {{errorMessage}}
            </p>
            <app-button class="w-100" btnSize="regular" btnText="Back to Forgot Password page" btnType="brand"
                [hasBtnText]="true" (click)="clearForgotPasswordForm()">
            </app-button>
        </p-dialog>
    </div>
</div>