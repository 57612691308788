import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppGuard } from './app.guard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoadGuard } from './core/guards/load.guard';
import { B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, CONSOLE_OPS, INTERVIEWER } from './general/constants/app-constants';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';

const user_role_login_route: any = {
  "b2b-admin": "b2b/admin/login",
  "b2b-candidate": "b2b/candidate/login",
  "b2b-interviewer": "b2b/interviewer/login",
  "ib-console-admin": "ib/console/login",
  "ib-ops-l1": "ib/console/login",
  "ib-interviewer": "b2c/interviewer/login",
  "b2c-candidate": "b2c/candidate/login"
};

const user_role_register_route: any = {
  "ib-interviewer": "b2c/interviewer/register",
  "b2c-candidate": "b2c/candidate/register"
};
const routes: Routes = [
  {
    path: 'b2b/admin/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-admin" }
  },
  {
    path: 'b2b/admin/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-admin" }
  },
  {
    path: 'b2b/interviewer/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-interviewer" }
  },
  {
    path: 'b2b/interviewer/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-interviewer" }
  },
  {
    path: 'b2b/candidate/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-candidate" }
  },
  {
    path: 'b2b/candidate/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2b-candidate" }
  },
  {
    path: 'ib/console/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "ib-console-admin" }
  },
  {
    path: "ib/console/forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "ib-console-admin" }
  },
  {
    path: 'b2c/interviewer/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "ib-interviewer" }
  },
  {
    path: 'b2c/interviewer/register',
    component: RegisterComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "ib-interviewer" }
  },
  {
    path: "b2c/interviewer/forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "ib-interviewer" }
  },
  {
    path: 'b2c/candidate/login',
    component: LoginComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2c-candidate" }
  },
  {
    path: 'b2c/candidate/register',
    component: RegisterComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2c-candidate" }
  },
  {
    path: "b2c/candidate/forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [AppGuard],
    canLoad: [LoadGuard],
    data: { user_role: "b2c-candidate" }
  },
  {
    path: 'ib/console',
    loadChildren: () => import('./ib-console/ib-console.module').then((m) => m.IbConsoleModule),
    canLoad: [LoadGuard],
    data: { user_role: CONSOLE_ADMIN }
  },
  {
    path: 'b2c/interviewer',
    loadChildren: () => import('./b2c/interviewer/interviewer.module').then((m) => m.InterviewerModule),
    canLoad: [LoadGuard],
    data: { user_role: INTERVIEWER }
  },
  {
    path: 'b2c/candidate',
    loadChildren: () => import('./b2c/candidate/candidate.module').then((m) => m.CandidateModule),
    canLoad: [LoadGuard],
    data: { user_role: B2C_CANDIDATE }
  },
  {
    path: 'b2b/admin',
    loadChildren: () => import('./b2b/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [LoadGuard],
    data: { user_role: B2B_ADMIN }
  },
  {
    path: 'b2b/interviewer',
    loadChildren: () => import('./b2b/interviewer/interviewer.module').then((m) => m.InterviewerModule),
    canLoad: [LoadGuard],
    data: { user_role: B2B_INTERVIEWER }
  },
  {
    path: 'b2b/candidate',
    loadChildren: () => import('./b2b/candidate/candidate.module').then((m) => m.CandidateModule),
    canLoad: [LoadGuard],
    data: { user_role: B2B_CANDIDATE }
  },
  {
    path: 'meeting-session',
    loadChildren: () =>
      import('./meeting-session/meeting-session.module').then(
        (m) => m.MeetingSessionModule
      )
  },
  {
    path: 'meeting',
    loadChildren: () =>
      import('./meeting/one-way/one-way.module').then(
        (m) => m.OneWayModule
      )
  },
  {
    path: 'playback',
    loadChildren: () =>
      import('./playback/playback.module').then(
        (m) => m.PlaybackModule
      )
  },
  { path: '', redirectTo: routeToLogin(), pathMatch: 'full' },
  { path: '**', redirectTo: routeToLogin() },
];

function routeToLogin() {
  let org_slug = window.location.origin.split('://')[1].split('.')[0];
  if (org_slug === 'ib' || window.location.port === '4200') {
    const route = localStorage.getItem('register') != null && localStorage.getItem('register') === 'true' ?
      user_role_register_route[localStorage.getItem('ib-user-role') || 'b2c-candidate'] :
      user_role_login_route[localStorage.getItem('ib-user-role') || 'b2c-candidate'];
    return route;
  } else {
    const route = user_role_login_route[localStorage.getItem('ib-user-role') || 'b2b-candidate'];
    return route;
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppGuard]
})
export class AppRoutingModule { }
