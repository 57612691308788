import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { B2B_ADMIN, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, CONSOLE_OPS, INTERVIEWER } from 'src/app/general/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class LoadGuard implements CanLoad {
  constructor(public router: Router) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log(localStorage.getItem('ib-user-role'),'role')
      console.log(route.data?.user_role)
    if((localStorage.getItem('ib-user-role') == route.data?.user_role || localStorage.getItem('ib-user-role')?.includes('ib-ops')) && localStorage.getItem('access-token')) {
      return true
    }
    switch(route.data?.user_role) {
      case CONSOLE_ADMIN: this.router.navigate(['/ib/console/login'])
      break
      case CONSOLE_OPS: this.router.navigate(['/ib/console/login'])
      break
      case B2B_ADMIN: this.router.navigate(['/b2b/admin/login'])
      break
      case INTERVIEWER: this.router.navigate(['/b2c/interviewer/login'])
      break
      case B2B_INTERVIEWER: this.router.navigate(['/b2b/interviewer/login'])
      break
      case B2C_CANDIDATE: this.router.navigate(['/b2c/candidate/login'])
      break
      default: this.router.navigate(['/b2b/candidate/login'])
    }
    return false
  }
}
