import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TokenStorageService } from 'src/app/core/services/common/token-storage.service';
import { B2B_ADMIN, B2B_CANDIDATE, B2B_INTERVIEWER, B2C_CANDIDATE, CONSOLE_ADMIN, CONSOLE_OPS, INTERVIEWER } from 'src/app/general/constants/app-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  @Input({ required: true }) email_id: string = '';
  @Input({ required: true }) organization_slug: string = '';

  userType: string = this.route.snapshot.data['user_role']

  password = new FormControl('', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}$')]);
  confirmPassword = new FormControl('', [Validators.required]);

  showPassword = false
  showCPassword = false
  loading = false;
  storageEndpoint = environment.storage_endpoint;
  loginLink = ''
  success = false

  errorPopup: boolean;
  errorMessage: string = '';

  step = 1;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public storageService: TokenStorageService,
    public authService: AuthService,
    public http: HttpClient
  ) {
    if (this.userType == INTERVIEWER) {
      this.loginLink = window.location.origin + '/b2c/interviewer/login'
    };
    if (this.userType == CONSOLE_ADMIN) {
      this.loginLink = window.location.origin + '/ib/console/login'
    };
    if (this.userType.includes(CONSOLE_OPS)) {
      this.loginLink = window.location.origin + '/ib/console/login'
    };
    if (this.userType == B2B_ADMIN) {
      this.loginLink = window.location.origin + '/b2b/admin/login'
    };
    if (this.userType == B2B_INTERVIEWER) {
      this.loginLink = window.location.origin + '/b2b/interviewer/login'
    };
    if (this.userType == B2B_CANDIDATE) {
      this.loginLink = window.location.origin + '/b2b/candidate/login'
    };
    if (this.userType == B2C_CANDIDATE) {
      this.loginLink = window.location.origin + '/b2c/candidate/login'
    };

  }

  ngOnInit() {
    //Clear all local storage variable before reset password
    this.storageService.clearLocalStorage();

    //Saving user type in local storageService
    this.storageService.saveUserRole(this.userType);
  }

  async resetPassword() {
    this.loading = true;

    this.http.post(environment.auth_endpoint + '/auth/reset-password', {
      email: this.email_id,
      role: this.storageService.getUserRole(),
      organization_slug: this.organization_slug,
      password: this.password.value
    }).subscribe({
      next: (res: any) => {
        console.log("Response from Backend's Reset Password", res);
        this.loading = false;
        this.step = 2;
        setTimeout(() => {
          this.router.navigate([this.loginLink]);
        }, 1000)
      },
      error: (err) => {
        if (err.error.message === 'Your access is restricted. Contact the administrator') {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "Your access is restricted. Contact the administrator";
          this.errorPopup = true;
        } else if (err.error.message === 'You do not have an account with provide email!. Please try with a registered email' || err.error.message === "Account does not exist for the provided email. Please use a registered email.") {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "Account does not exist for the provided email. Please use a registered email.";
          this.errorPopup = true;
        } else {
          console.log("Error is raised while trying to verify Password with following message: ", err.error);
          this.errorMessage = "An error occurred while trying to Login!. Please try again.";
          this.errorPopup = true;
        }
        this.loading = false;
      }
    })
  }
}